import React, { StrictMode } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, useMutation, gql } from '@apollo/client';
import {
  ChakraProvider,
  ColorModeScript,
  Icon,
  useBreakpointValue,
} from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

import App from './App';
import Contact from './components/Contact';
import { Theme } from './themes/_theme';
import { Font } from './config/Font';
import i18n from './i18n/i18n.js'; 

const container = document.getElementById('root');

const client = new ApolloClient({
  uri: 'http://localhost:4000/',
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(container);
const Blur = props => {
  return (
    <Icon
      width={useBreakpointValue({ base: '40%', md: '40%', lg: '60%' })}
      zIndex={'-1'}
      height="560px"
      viewBox="0 0 528 560"
      fill="Background"
    opacity={0.25}
    filter= 'blur(50px)'
      {...props}
    >
      <circle cx="244" cy="106" r="139" fill="#1B5748" />
      <circle cy="0" cx="0" r="119" fill="#A3893B" />
    </Icon>
  );
};
root.render(
 
    <ChakraProvider theme={Theme}>
      <ApolloProvider client={client}>
      <I18nextProvider i18n={i18n}>
      <Font />
      <ColorModeScript />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="contact" element={<Contact />} />
        </Routes>
       
        <Blur
          position={'fixed'}
          top={-10}
          right={-400}
        
        />
        <Blur
          position={'fixed'}
          top={40}
          right={400}
        
        />
        </BrowserRouter>
      </I18nextProvider>
      </ApolloProvider>
    </ChakraProvider>
  
);
