import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Button,
  useDisclosure,
  Stack,
  Spacer,
  Icon,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { FiArrowUpCircle } from 'react-icons/fi';
import LogoEn from '../assets/Logo/logoEn.jsx';
import LogoAr from '../assets/Logo/logoAr.jsx';

import React from 'react';

const Links = [
  'header.home',
  'header.services',
  'header.projects',
  'header.careers',
];

export default function Header() {
  const [currentLanguage, setCurrentLanguage] = React.useState('en');
  const { t, i18n } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === 'en' ? 'ar' : 'en';
    document.documentElement.dir = i18n.language === 'en' ? 'rtl' : 'ltr';
    setCurrentLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };
  return (
    <>
      <Box
        name="header.home"
        fontSize={'xl'}
        m={{ base: 'auto', md: 10, lg: 10 }}
        px={4}
      >
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            bg={'transparent'}
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ lg: 'none' }}
            onClick={isOpen ? onClose : onOpen}
            _hover={{
              bg: 'transparent',
            }}
          />

          <Box ml={{ base: 8, md: 0, lg: 10 }}>
            {i18n.language === 'ar' ? <LogoAr /> : <LogoEn />}
          </Box>
          <Spacer></Spacer>
          <Button
            // mx={5}
            variant={'ghost'}
            colorScheme="green"
            size={{ base: 'sm', md: 'md', lg: 'lg' }}
            px={8}
            onClick={toggleLanguage}
          >
            {currentLanguage === 'en' ? 'عربي' : 'English'}
          </Button>
          <ScrollLink
            to={'header.home'}
            spy={true}
            smooth={true}
            offset={0}
            duration={800}
            as={Button}
          >
            <Icon
              zIndex={1}
              cursor={'pointer'}
              position={'fixed'}
              bottom={{ base: 8, md: 18, lg: 20 }}
              right={{ base: 8, md: 18, lg: 20 }}
              color={'#A3893B'}
              w={50}
              h={50}
              as={FiArrowUpCircle}
            ></Icon>
          </ScrollLink>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ lg: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map(link => (
                <ScrollLink
                  key={link}
                  to={link}
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={800}
                  as={Button}
                >
                  <Button
                    _hover={{
                      bg: 'transparent',
                      color: 'gray.700',
                      borderColor: 'gray.700',
                      borderWidth: '2px',
                    }}
                    fontSize={'em'}
                    fontWeight={'none'}
                    variant={'none'}
                  >
                    {t(link)}
                  </Button>
                </ScrollLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
