import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        header: {
          home: 'Home',
          services: 'Services',
          projects: 'Projects',
          careers: 'Careers',
          get_in_touch: 'Get in touch',
        },
        hero: {
          title: 'Crafting Digital Dreams',
          subtitle:
            // eslint-disable-next-line no-multi-str
            'Transform your visions into reality. With each line of code, we \
      create extraordinary digital experiences that inspire and empower. \
      Let us craft your dream into a seamless, cutting edge solution',
          button: 'Get in touch',
        },
        services: {
          title: 'Services',
          subtitle:
            'Discover a range of innovative services that pave the way to a brighter future.',
          bigDataTitle: 'Big Data, AI, and ML',
          bigDataDescription:
            'Unleash the power of intelligent automation with our Big Data, AI, and ML solutions.',
          webDevTitle: 'Web Development',
          webDevDescription:
            'Unlock the potential of the web with our expert web development solutions.',
          customAppTitle: 'Custom Application Development',
          customAppDescription:
            'Your unique vision deserves a custom solution. We bring your ideas to life.',
          saasTitle: 'SaaS Application',
          saasDescription:
            'Embrace the future of software with our cutting-edge SaaS application development.',
          uiuxTitle: 'UI/UX Design',
          uiuxDescription:
            'Elevate your digital experience with our striking UI/UX design services.',
          mobileAppTitle: 'Mobile App Development',
          mobileAppDescription:
            'Revolutionize your business with our cutting-edge mobile app development services.',
          readMore: 'READ MORE',
        },
        ourwork: {
          "title": "Our Work",
          "subtitle": "Showcasing Our Successful Projects and Innovations in Action",
          "leftArrowLabel": "left-arrow",
          "rightArrowLabel": "right-arrow",
          "cards": [
            {
              "title": "Beauty Ai",
              "text": "Makeup recommendation Application for women."
            },
            {
              "title": "Beauty Ai",
              "text": "Makeup recommendation Application for women."
            },
            {
              "title": "Beauty Ai",
              "text": "Makeup recommendation Application for women."
            }
          ]
        },
        careers: {
          "title": "Careers",
          "subtitle": "A World of Possibilities: Explore Careers with Us",
          "applyNow": "APPLY NOW",
          "cards": [
            {
              "title": "Junior Web Developer",
              "location": "Location: On Site",
              "fullTime": "Full time"
            },
            {
              "title": "Junior Web Developer",
              "location": "Location: On Site",
              "fullTime": "Full time"
            },
            {
              "title": "Junior Web Developer",
              "location": "Location: On Site",
              "fullTime": "Full time"
            },
            {
              "title": "FullStack Web Developer",
              "location": "Location: On Site",
              "fullTime": "Full time"
            }
          ]
        },
        "footer": {
          "services": "Services",
          "mobileAppDev": "Mobile App Development",
          "webDev": "Web Development",
          "saasApp": "SaaS Application",
          "customApp": "Custom Application",
          "uiuxDesign": "UI/UX Design",
          "bigDataAI": "Big Data, AI, and ML",
          "contact": "Contact",
          "phoneNumber": "(021) 28 00 00",
          "email": "info@omransoftware.com",
          "address": "Chettia, Chlef",
          "socialMedia": "Social media",
          "poweredBy": "© 2023 omransoftware | Powered by omransoftware"
        },
        "joinOurTeam": {
          "pageTitle": "Tell Us About Your Project",
          "pageDescription": "Share your project vision and enable us to deliver tailored solutions",
          "fullName": "Full name",
          "email": "Email address",
          "phoneNumber": "Phone Number",
          "projectTitle": "Project title",
          "projectType": "Project type",
          "projectDescription": "Project Description",
          "submit": "Submit",
          "projectTypes": {
            "mobileAppDev": "Mobile App Development",
            "webDev": "Web Development",
            "saasApp": "SaaS Application",
            "customApp": "Custom Application",
            "uiuxDesign": "UI/UX Design",
            "bigDataAI": "Big Data, AI, and ML"
          }
        }
        
      },
    },
    ar: {
      translation: {
        header: {
          home: 'الرئيسية',
          services: 'الخدمات',
          projects: 'المشاريع',
          careers: 'الوظائف',
          get_in_touch: 'تواصل معنا',
        },
        hero: {
          title: 'تحقيق أحلام رقمية',
          subtitle: 'نحو حقيقة أحلامك. نحن نصنع تجارب رقمية استثنائية تلهم وتمكن، دعنا نحقق حلمك بحلاً متقن وحديث.',
          button: 'تواصل معنا',
        },
        services: {
          title: 'الخدمات',
          subtitle: 'استكشف خدمات مبتكرة تمهد الطريق نحو مستقبل أفضل.',
          bigDataTitle: 'البيانات الكبيرة، الذكاء الاصطناعي والتعلم الآلي',
          bigDataDescription: 'اطلق سر سيطرة الأتمتة الذكية مع حلولنا للبيانات الكبيرة والذكاء الاصطناعي والتعلم الآلي.',
          webDevTitle: 'تطوير الويب',
          webDevDescription: 'فتح إمكانيات الويب مع حلولنا المتخصصة في تطوير المواقع.',
          customAppTitle: 'تطوير تطبيقات مخصصة',
          customAppDescription: 'رؤيتك الفريدة تستحق حلاً مخصصًا. دعونا نحول أفكارك إلى حقيقة.',
          saasTitle: 'تطبيقات البرمجيات كخدمة',
          saasDescription: 'اعتمد مستقبل البرمجيات مع تطوير تطبيقات البرمجيات كخدمة الحديثة لدينا.',
          uiuxTitle: 'تصميم واجهة المستخدم / تجربة المستخدم',
          uiuxDescription: 'ارتق بتجربتك الرقمية مع خدمات تصميم واجهة المستخدم / تجربة المستخدم الرائعة.',
          mobileAppTitle: 'تطوير تطبيقات الجوال',
          mobileAppDescription: 'قم بثورة في عملك مع خدمات تطوير تطبيقات الجوال الحديثة.',
          readMore: 'قراءة المزيد',
        },
        ourwork: {
          title: 'أعمالنا',
          subtitle: 'عرض مشاريع ناجحة وابتكارات في العمل',
          leftArrowLabel: 'السهم الأيسر',
          rightArrowLabel: 'السهم الأيمن',
          cards: [
            {
              title: 'Beauty Ai',
              text: 'تطبيق توصية مكياج للنساء.',
            },
            {
              title: 'Beauty Ai',
              text: 'تطبيق توصية مكياج للنساء.',
            },
            {
              title: 'Beauty Ai',
              text: 'تطبيق توصية مكياج للنساء.',
            },
          ],
        },
        careers: {
          title: 'الوظائف',
          subtitle: 'استكشف مجالات الوظائف معنا',
          applyNow: 'قدّم الآن',
          cards: [
            {
              title: 'مطوّر ويب مبتدئ',
              location: 'الموقع: في المكتب',
              fullTime: 'وقت كامل',
            },
            {
              title: 'مطوّر ويب مبتدئ',
              location: 'الموقع: في المكتب',
              fullTime: 'وقت كامل',
            },
            {
              title: 'مطوّر ويب مبتدئ',
              location: 'الموقع: في المكتب',
              fullTime: 'وقت كامل',
            },
            {
              title: 'مطوّر ويب FullStack',
              location: 'الموقع: في المكتب',
              fullTime: 'وقت كامل',
            },
          ],
        },
        footer: {
          services: 'الخدمات',
          mobileAppDev: 'تطوير تطبيقات الجوال',
          webDev: 'تطوير الويب',
          saasApp: 'تطبيقات البرمجيات كخدمة',
          customApp: 'تطبيقات مخصصة',
          uiuxDesign: 'تصميم واجهة المستخدم',
          bigDataAI: 'البيانات الكبيرة، الذكاء الاصطناعي والتعلم الآلي',
          contact: 'تواصل معنا',
          phoneNumber: '(021) 28 00 00',
          email: 'OmranSoftware@gmail.com',
          address: 'الشطية، الشلف',
          socialMedia: 'وسائل التواصل الاجتماعي',
          poweredBy: '© 2023 omransoftware | مدعوم بواسطة omransoftware',
        },
        joinOurTeam: {
          pageTitle: 'شاركنا رؤية مشروعك',
          pageDescription: 'شاركنا رؤيتك ودعنا نقدم لك حلاً مخصصًا',
          fullName: 'الاسم الكامل',
          email: 'عنوان البريد الإلكتروني',
          phoneNumber: 'رقم الهاتف',
          projectTitle: 'عنوان المشروع',
          projectType: 'نوع المشروع',
          projectDescription: 'وصف المشروع',
          submit: 'إرسال',
          projectTypes: {
            mobileAppDev: 'تطوير تطبيقات الجوال',
            webDev: 'تطوير الويب',
            saasApp: 'تطبيقات البرمجيات كخدمة',
            customApp: 'تطبيقات مخصصة',
            uiuxDesign: 'تصميم واجهة المستخدم',
            bigDataAI: 'البيانات الكبيرة، الذكاء الاصطناعي والتعلم الآلي',
          },
        },
      },
    },
    
  },
  lng: 'en', // Default language
  fallbackLng: 'en', // Fallback language
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
