import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Stack,
  Flex,
  Heading,
  Text,
  Button,
} from '@chakra-ui/react';

import Lottie from 'react-lottie';
import computer from '../lotties/computer.json';

export default function HeroSection() {
  const { t } = useTranslation();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: computer,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
   
  return (
    <Container maxW={'12xl'}>
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 20 }}
        py={{ base: 20, md: 2, lg: 2 }}
        px={{ base: 2, md: 2, lg: 20 }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Stack flex={1.5} spacing={{ base: 5, md: 5 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
            color={'gray.500'}
          >
            <Text fontSize={{ base: '2.5rem', md: '4rem' }} color={'gray.800'}>
            {t('hero.title')}
            </Text>
          </Heading>
          <Text maxW={'28em'} fontSize={'1.3rem'} color={'gray.800'}>
          {t('hero.subtitle')}
          </Text>
          
        </Stack>
        <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}
        >
         
        
          <Lottie options={defaultOptions} />
        </Flex>
      </Stack>
    </Container>
  );
}
