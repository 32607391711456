import React from 'react';
import {
  Container,
  Stack,
  Flex,
  Heading,
  Text,
  Grid,
  GridItem,
  Card,
  CardBody,
  Spacer,
  Button,
} from '@chakra-ui/react';

import Location from '../assets/Icons/location.jsx';
import Clock from '../assets/Icons/clock.jsx';
import { useTranslation } from 'react-i18next';

function Careers() {
  const { t } = useTranslation();
  const cards = [
    {
      title: 'Junior Web Developer',
      iconLocation: <Location />,
      TextLocation: ' Location : On Site',
      iconClock: <Clock />,
      TextClock: 'Full time',
    },
    {
      title: 'Junior Web Developer',
      iconLocation: <Location />,
      TextLocation: ' Location : On Site',
      iconClock: <Clock />,
      TextClock: 'Full time',
    },
    {
      title: 'Junior Web Developer',
      iconLocation: <Location />,
      TextLocation: ' Location : On Site',
      iconClock: <Clock />,
      TextClock: 'Full time',
    },
    {
      title: 'FullStack Web Developer',
      iconLocation: <Location />,
      TextLocation: ' Location : On Site',
      iconClock: <Clock />,
      TextClock: 'Full time',
    },
  ];
  return (
    <>
      <Container name={'header.careers'} maxW={'100rem'}>
        <Heading mb={10} textAlign={'center'}>
          {t('careers.title')}
        </Heading>
        <Flex mb={'8em'} justifyContent={'center'}>
          <Text
            w={'28em'}
            textAlign={'center'}
            fontSize={{ base: '1em', md: '1.5em' }}
          >
            {t('careers.subtitle')}
          </Text>
        </Flex>
        {/* <Grid templateRows="repeat(1, 1fr)">
          <GridItem>
            {cards.map((card, index) => (
              <Card
                mt={10}
                key={index}
                borderRadius={20}
                h={{ base: '35vh', md: '8em' }}
                justifyContent={'center'}
                alignItems={{ base: 'none', sm: 'center', md: 'center' }}
                direction={{ base: 'column', sm: 'row' }}
                overflow="hidden"
                variant="outline"
                bg={'rgb(255,255,255,0.5)'}
                style={{
                  boxShadow:
                    ' rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                }}
                size={{ base: 'sm', md: 'lg' }}
              >
                <Flex>
                  <CardBody>
                    <Heading size={{ base: 'sm', md: 'md' }}>
                      {t(`careers.cards.${index}.title`)}
                    </Heading>
                  </CardBody>
                </Flex>
                <Spacer display={{ base: 'none', md: 'block' }} />
                <Flex mt={{ base: 4, sm: 0 }}>
                {card.iconLocation}
                  <Text fontSize={{ base: 'sm', md: 'lg' }}>
                    {' '}
                    {t(`careers.cards.${index}.location`)}
                  </Text>
                </Flex>
                <Spacer display={{ base: 'none', md: 'block' }} />
                <Flex mt={{ base: 4, sm: 0 }}>
                  {card.iconClock}
                  <Text> {t(`careers.cards.${index}.fullTime`)}</Text>
                </Flex>
                <Spacer display={{ base: 'none', md: 'block' }} />
                <Stack mt={{ base: 12, sm: 0 }}>
                  <Button
                    borderRadius={'20px'}
                    bgGradient="linear(to-r, #24574A, #183A31)"
                    size={{ base: 'md', sm: 'sm', md: 'lg' }}
                    fontWeight={'medium'}
                    mx={{ base: 7, md: 5, lg: 20 }}
                    mb={{ base: 'auto', md: 0 }}
                    px={{ base: 24, md: 8, lg: 12 }}
                    color={'white'}
                    boxShadow={
                      '0px 1px 25px -5px  rgba(0, 0, 0, 0.3), 0 10px 10px -5px rgba(0, 0, 0, 0.3)'
                    }
                    _hover={{
                      borderWidth: '2px',
                      borderColor: 'gray.700',
                      variant: 'outline',
                      color: 'gray.700',
                      bg: 'transparent',
                      boxShadow:
                        '0px 1px 14px -5px  rgba(0, 0, 0, 0.3), 0 10px 10px -5px rgba(0, 0, 0, 0.3)',
                      transition: ' 0.8s',
                    }}
                  >
                    {t(`careers.applyNow`)}
                  </Button>
                </Stack>
              </Card>
            ))}
          </GridItem>
        </Grid> */}
      </Container>
    </>
  );
}

export default Careers;
