import {
  Box,
  chakra,
  Container,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  useColorModeValue,
  Flex,
  Icon,
  Spacer,
} from '@chakra-ui/react';

import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaLocationArrow,
  FaPhone,
  FaRegEnvelope,
  FaTwitter,
} from 'react-icons/fa';
import FooterLogo from '../assets/Logo/footerLogo.jsx';
import { useTranslation } from 'react-i18next';

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function Footer() {
  const { t } = useTranslation();
  return (
    <Box bg={'#24574A'} color={'white'}>
      <Container as={Stack} maxW={'8xl'} py={10}>
        <SimpleGrid
          templateColumns={{
            md: '1fr 1fr 1fr',
          }}
          spacing={8}
          gap={5}
        >
          <Stack justifyContent={'center'} align={'center'} spacing={6}>
            <Box align={{ base: 'center' }}>
              <FooterLogo />
            </Box>
          </Stack>
          <Spacer />
          <Stack align={'flex-start'}>
            <ListHeader>{t('footer.contact')}</ListHeader>
            <Box as="a" href={'mailto:'+t('footer.email')}>
              <Icon as={FaRegEnvelope} />
              <Text mx={{ base: 5, md: 3, lg: 5 }} display={'inline'}>
                {t('footer.email')}
              </Text>
            </Box>
            <Stack
              gridColumnStart={{ md: 2, lg: 4 }}
              display={{ md: 'flex', base: 'none' }}
              mt={{ base: 'none', md: 5 }}
              align={'flex-start'}
            >
              <ListHeader>{t('footer.socialMedia')}</ListHeader>
              <Stack direction={'row'} spacing={6}>
              <SocialButton label={'LinkedIn'} href={'https://www.linkedin.com/company/omran-software'}>
                  <FaLinkedinIn />
                </SocialButton>
                <SocialButton label={'Facebook'} href={'http://www.facebook.com/omransoftware'}>
                  <FaFacebook />
                </SocialButton>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            display={{ base: 'flex', md: 'none' }}
            gridColumnStart={{ md: 2, lg: 4 }}
            mt={{ base: 'none', md: 5 }}
            align={'flex-start'}
          >
            <ListHeader>{t('footer.socialMedia')}</ListHeader>
            <Stack direction={'row'} spacing={6}>
            <SocialButton label={'LinkedIn'} href={'https://www.linkedin.com/company/omran-software'}>
                  <FaLinkedinIn />
                </SocialButton>
                <SocialButton label={'Facebook'} href={'http://www.facebook.com/omransoftware'}>
                  <FaFacebook />
                </SocialButton>
            </Stack>
          </Stack>
        </SimpleGrid>
      </Container>
      <Flex justifyContent={'center'}>
        <Text my={10} fontSize={'sm'}>
          {t('footer.poweredBy')}
        </Text>
      </Flex>
    </Box>
  );
}
