import { extendTheme } from '@chakra-ui/react';

export const Theme = extendTheme(
    {
        fonts: {         
            heading: "Syne  , Cairo",
            body: "Syne , Cairo"
        },
        fontDisplay: 'swap' 
    }
);