import React from 'react';
import { Box, Stack } from '@chakra-ui/react';

import {
  Layout,
  HeroSection,
  Services,
  Ourwork,
  Careers,
  Footer,
} from './components/_index.js';

function App() {
  return (
    <>
      <Layout>
        <Stack>
          <Box mb={{ base: '5em', md: '12em' }}>
            <HeroSection />
          </Box>
          {/* <Box mb={{ base: '100em', md: '60em', lg: '35em' }}>
            <Services />
          </Box> */}
          {/* <Box mb={{ base: 20, md: '-10em' }}>
            <Ourwork />
          </Box>
          <Box mb={{ base: 20, md: 60 }}>
            <Careers />
          </Box> */}
        </Stack>
      </Layout>
      <Footer />
    </>
  );
}

export default App;
