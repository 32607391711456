import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  FormLabel,
  Img,
  Select,
  Textarea,
  InputLeftAddon,
  InputGroup,
} from '@chakra-ui/react';

import contact from '../assets/ImgProject/contact.svg';
import { useTranslation } from 'react-i18next';

export default function JoinOurTeam() {
  const { t } = useTranslation();
  return (
    <Box   position={'relative'}>
      <Container
        as={SimpleGrid}
        maxW={'8xl'}
        h={'100vh'}
        columns={{ base: 1, md: 1, lg: 2 }}
        spacing={{ base: 10, md: 32, lg: 32 }}
        my={{ base: 5, md: 10, lg: 10 }}
       
      >
        <Stack
          align={'center'}
          py={{ base: 10, md: 0, lg: 32 }}
          my={{ base: 0, md: 10, lg: 32 }}
          display={{ base: 'none', lg: 'inline-block' }}
          spacing={{ base: 10, md: 20 }}
        >
          <Img w={'100%'} src={contact}></Img>
        </Stack>
        <Stack
          h={'100vh'}
          bg={'#E8E8E8'}
          rounded={'xl'}
          spacing={{ base: 8 }}
          mx={{ base: 3, md: 8, lg: 10 }}
          maxW={{ base: '100%', md: '4xl', lg: '8xl' }}
          px={{ base: 10, md: 10, lg: 10 }}
          py={{ base: 5, md: 5, lg: 14 }}
        >
          <Stack spacing={4}>
            <Heading
              color={'gray.800'}
              lineHeight={1.1}
              fontSize={{ base: '2xl', sm: '3xl', md: '3xl' }}
            >
               {t('joinOurTeam.pageTitle')}
            </Heading>
            <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'sm' }}>
            {t('joinOurTeam.pageDescription')}
            </Text>
          </Stack>
          <Box as={'form'} mt={10}>
            <Stack spacing={4}>
              <FormLabel mb={-2}>{t('joinOurTeam.fullName')}</FormLabel>
              <Input
                placeholder="Fullname"
                bg={'gray.50'}
                border={0}
                color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
              />
              <FormLabel mb={-2}>{t('joinOurTeam.projectType')}</FormLabel>
              <Input
                placeholder="ex : example@gmail.com"
                bg={'gray.50'}
                border={0}
                color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
              />
              <FormLabel mb={-2}>{t('joinOurTeam.phoneNumber')}</FormLabel>
              <InputGroup>
                <InputLeftAddon children="+213" />
                <Input
                  bg={'gray.50'}
                  border={0}
                  color={'gray.500'}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                  type="tel"
                  placeholder="phone number"
                />
              </InputGroup>

              <FormLabel mb={-2}>{t('joinOurTeam.projectTitle')}</FormLabel>
              <Input
                placeholder="ex : my awesome app"
                bg={'gray.50'}
                border={0}
                color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
              />
              <FormLabel mb={-2}>{t('joinOurTeam.projectType')}</FormLabel>
              <Select  bg={'gray.50'}>
                <option    value="option1">{t('joinOurTeam.projectTypes.mobileAppDev') }</option>
                <option value="option2">{t('joinOurTeam.projectTypes.webDev')}</option>
                <option value="option3">{t('joinOurTeam.projectTypes.saasApp')}</option>
                <option value="option3">{t('joinOurTeam.projectTypes.customApp')}</option>
                <option value="option3">{t('joinOurTeam.projectTypes.uiuxDesign')}</option>
                <option value="option3">{t('joinOurTeam.projectTypes.bigDataAI')}</option>
              </Select>
              <FormLabel mb={-2}>{t('joinOurTeam.projectDescription')}</FormLabel>
              <Textarea
                borderRadius={10}
                placeholder="describe your project in detail"
                size="sm"
                resize={'none'}
                bg={'gray.50'}
              />
            </Stack>
            <Button
              fontFamily={'heading'}
              mt={8}
              w={'full'}
              bgGradient="linear(to-r, #24574A, #183A31)"
              color={'white'}
              _hover={{
                borderWidth: '2px',
                borderColor: 'gray.700',
                variant: 'outline',
                color: 'gray.700',
                bg: 'transparent',
                boxShadow:
                  '0px 1px 14px -5px  rgba(0, 0, 0, 0.3), 0 10px 10px -5px rgba(0, 0, 0, 0.3)',
                transition: ' 0.8s',
              }}
            >
              {t('joinOurTeam.submit')}
            </Button>
          </Box>
          
        </Stack>
      </Container>
    </Box>
  );
}
